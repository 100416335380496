.App {
  margin-bottom: 20px;
}
body {
  background-color: #000000 !important;
  font-family: "Monument extended 400",sans-serif;;
  color: #333;
  font-size: 14px;
  line-height: 20px;
}

.app-body{
  margin-top: 80px;
  min-height: 70%;
} 

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.banner {
  padding-top: 50px !important;
  width: 100% !important;
}

.MuiButton-root {
  background-color: #fff !important;
  color: #000 !important;
  font-family: "Monument extended 400", sans-serif !important;
}

.welcome {
  font-family: 'Monument extended 400', sans-serif;
  padding-top: 200px;
  color: #fff;
  font-size: 8vw;
  line-height: 1;
  font-weight: 400;
}

.carousel-container {
  height: 540px;
}

.button-submit.Mui-disabled {
  background-color: gray !important;
}

.div-link:hover {
  cursor: pointer;
}

@media only screen and (min-width: 960px) {
  .banner {
    padding-top: 10px !important;
  }
  .carousel-container {
    height: 1100px;
  }

  .contact-box {
    margin-left: 30px;
    margin-right: 30px;
  }
}
