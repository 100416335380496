.policy {
    font-family: "Monument extended 400", sans-serif;
}

.policy-header {
    color: white;
    font-size: 2rem !important;
    text-align: center;
    padding-top: 45%;
    padding-bottom: 35%;
    font-family: "Monument extended 800", sans-serif !important;
}

@media only screen and (min-width: 500px) {
    .policy-header {
        padding-top: 20%;
        padding-bottom: 20%;
    }
}

@media only screen and (min-width: 820px) {
    .policy-header {
        padding-top: 35%;
        padding-bottom: 35%;
    }
}

@media only screen and (min-width: 1080px) {
    .policy-header {
        padding-top: 5%;
        padding-bottom: 5%;
    }
}

@media only screen and (min-width: 1440px) {
    .policy-header {
        padding-top: 5%;
        padding-bottom: 5%;
    }
}

.policy-text{
    color: white;
    margin-left: 10%;
    margin-right: 10%;
    display: block;
}