.width-div-grid2 {
    /* width: 80vw; */
    /* margin-right: auto;
    margin-left: auto; */
    /* display: -ms-grid; */
    /* position: relative; */
    /* -ms-grid-columns: 1fr 1fr; */
    /* -ms-grid-rows: auto auto auto auto; */
    /* flex-direction: column; */
    /* justify-content: center; */
    align-items: center;
    /* -webkit-box-pack: center;
    -webkit-justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center; */
     /* grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto auto; */
}

.imgObjective{
    text-align: "center";
    padding: 0;
    height: 570px;
    width: 570px;
    position: "relative";
    display: "block";
    opacity: 1;
    background-size: "100%";
    background-position: "center center";
    background-repeat: "no-repeat";
    background-color: #000!important;
    border: 0!important;
}
.heading-4 {
    font-family: 'Monument extended 400', sans-serif;
    color: #fff!important;
    font-size: 1.8vw;
    font-weight: 400;
    text-decoration: none;
}

/* .MuiPaper-root{
    background-color: #000!important;
} */

.px-objectives {
    padding: 8px 8px 32px 32px!important;
}

@media screen and (max-width: 991px){
    .heading-4 {
        font-size: 4vw;
        line-height: 1.4;
    }
    
    .imgObjective{ 
        height: 320px;
        width: 320px; 
    }
    
}




@media screen and (max-width: 479px){
    .heading-4 {
        font-size: 5vw;
        line-height: 1.4;
    }
    
    .imgObjective{
        height: 360px;
        width: 360px;

    }

    .px-objectives {
        padding: 8px 16px 8px 16px!important;
    }
}


