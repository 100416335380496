.section-course {    
    background-image: url("https://uploads-ssl.webflow.com/5fc586d9dd2ecfcfd479e2aa/600ff6d14c1e3792717fd797_Pattern-SC.png");
    background-position: 0% 0%;
    background-size: 500px;
    background-attachment: scroll;
}
.section-course.gc{    
    background-image: url("https://uploads-ssl.webflow.com/5fc586d9dd2ecfcfd479e2aa/600ff8a0249e71919065f68f_Pattern-GC.png"); 
}

.section-course.bd{    
    background-image: url("https://uploads-ssl.webflow.com/5fc586d9dd2ecfcfd479e2aa/600ff8d9fe11be93443678e8_Pattern-BD.png"); 
}

.heading-2-course{
    
    color: rgb(255, 255, 255);
    font-family: "Monument extended 400", sans-serif;
    font-size: 1.8vw;
    font-weight: 400;
    text-decoration: none;
    text-align: left;
}

.course-img{
    padding: 6vw!important;
}
  
@media screen and (max-width: 991px) {
    .heading-2-course{
     
        text-align: left;
        line-height: 1.4;
        font-size: 5vw;
    }

    .course-details {
        padding: 32px!important;
    }

    .course-paragraph {   
        font-size: 13px !important; 
    }
    
     
}