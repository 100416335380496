.image-10a {
  height: 200px;
}

.button-3 {
  border-color: white !important;
  color: black !important;
  background: yellow;
}

.button-submit {
  color: black !important;
  background: white !important;
  font-size: larger !important;
  padding: 6px 30px !important;
}
.image-lock {
  -webkit-filter: grayscale(100%);
}
