.width-div-banner {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
}

.width-div-banner.absolute {
  position: absolute;
  flex-direction: column;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
}

.width-div-banner.horizontal {
  width: 30vw;
  margin-right: 3vw;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
}
