.heading-2 {
    font-family: 'Monument extended 400', sans-serif;
    color: #fff;
    font-size: 1.8vw;
    font-weight: 400;
    text-decoration: none;
}

.heading-2.black {
    color: #141414;
}
.submit-button {
    display: block;
    margin-top: 43px;
    margin-right: auto;
    margin-left: auto;
    padding: 12px 37px;
    background-color: #141414;
    font-family: 'Monument extended 400', sans-serif;
    color: #fff;
}